<template>
  <div class="page">Not found qr</div>
</template>

<script>
//   import PersonalAccess from "@/components/4laps/PersonalAccess";
//   import Button from "@/components/common/Button.vue";
//   import axios from "axios";

export default {
  name: "Error",
  components: {
    //   PersonalAccess,
    //   Button,
  },
  data() {
    return {
      phone: "",
    };
  },
  watch: {},
  methods: {
    submit() {},
  },
};
</script>

<style scoped lang="scss"></style>
